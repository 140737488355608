import $ from 'jquery';
import JsSIP from 'jssip';
import './style.css';
import 'fontawesome/css/font-awesome.min.css';
import MyRingback from './audio/fire-truck-air-horn_daniel-simion.mp3';
import logoAnura from './images/logo_anura.png'

const app = document.getElementById('app');
if (app) {
  app.innerHTML = require('./main.html');
}

/* 
 * How To Use:
 * 1. fill in valid credentials in the config below
 * 2. run the JsFiddle and enjoy
 */
var session;
var config;

var incomingCallAudio = new window.Audio(MyRingback);
console.log(incomingCallAudio);
incomingCallAudio.loop = true;
incomingCallAudio.crossOrigin = "anonymous";

var phone;
var callOptions = {
    mediaConstraints: {
        audio: true,
        video: false
    }
};

$(document).ready(function () {

    config = JSON.parse(localStorage.getItem('config'));
    if(config) { 
        if(config.uri) { $('#uriField').val(config.uri) }
        if(config.ws) { $('#wsField').val(config.ws) }
        if(config.password) { $('#passwordField').val(config.password) }
        if(config.name) { $('#nameField').val(config.name) }
    } else {
        config={};
    }
    console.log('sessiong config',config);



    $('#errorMessage').show();
    $('#wrapper').hide();

    $('#connectCall').click(function () {
        var dest = $('#toField').val();
        phone.call(dest, callOptions);
        updateUI();
    });


    $('#answer').click(function () {
        session.answer(callOptions);
    });

    var hangup = function () {
        session.terminate();
    };

    $('#hangUp').click(hangup);
    $('#reject').click(hangup);

    $('#mute').click(function () {
        console.log('MUTE CLICKED');
        if (session.isMuted().audio) {
            session.unmute({
                audio: true
            });
        } else {
            session.mute({
                audio: true
            });
        }
        updateUI();
    });
    $('#toField').keypress(function (e) {
        if (e.which === 13) { //enter
            $('#connectCall').click();
        }
    });
    $('#inCallButtons').on('click', '.dialpad-char', function (e) {
        var $target = $(e.target);
        var value = $target.data('value');
        session.sendDTMF(value.toString());
    });

    $('#registerButton').click(function() {
  
        config.ws=$('#wsField').val();
        config.uri=$('#uriField').val();
        config.password=$('#passwordField').val();
        config.name=$('#nameField').val();

        console.log('register config',config);

        if (config.ws && config.uri && config.password && config.name) {   
            localStorage.setItem('config',  JSON.stringify(config)); 
            register();
        }
        updateUI();

    })

    grantNotification();

});


function updateUI() {
    if (phone) {
        $('#errorMessage').hide();
        $('#wrapper').show();
        if (session) {
            if (session.isInProgress()) {
                if (session.direction === 'incoming') {
                    $('#incomingCallNumber').html(session.remote_identity.uri);
                    $('#incomingCall').show();
                    $('#callControl').hide()
                    $('#incomingCall').show();
                } else {
                    $('#callInfoText').html('Ringing...');
                    $('#callInfoNumber').html(session.remote_identity.uri.user);
                    $('#callStatus').show();
                }

            } else if (session.isEstablished()) {
                $('#callStatus').show();
                $('#incomingCall').hide();
                $('#callInfoText').html('In Call');
                $('#callInfoNumber').html(session.remote_identity.uri.user);
                $('#inCallButtons').show();
                incomingCallAudio.pause();
            }
            $('#callControl').hide();
        } else {
            $('#incomingCall').hide();
            $('#callControl').show();
            $('#callStatus').hide();
            $('#inCallButtons').hide();
            incomingCallAudio.pause();
        }
        //microphone mute icon
        if (session && session.isMuted().audio) {
            $('#muteIcon').addClass('fa-microphone-slash');
            $('#muteIcon').removeClass('fa-microphone');
        } else {
            $('#muteIcon').removeClass('fa-microphone-slash');
            $('#muteIcon').addClass('fa-microphone');
        }
    } else {
        $('#wrapper').hide();
        $('#errorMessage').show();
    }
}


function register() {
    console.log("Hello JSSIP!");

    var socket = new JsSIP.WebSocketInterface(config.ws); // FILL WSS SERVER

    var transport = '';
    var t = /^(ws)s?:/.exec(config.ws);
    if(t && ("1" in t) && t[1].length > 0) {
        transport = ';transport=' + t[1];
    }

    var configuration = {
        sockets: [socket],
        'uri': 'sip:'+config.uri, // FILL SIP URI HERE like sip:sip-user@your-domain.bwapp.bwsip.io
        'password': config.password, // FILL PASSWORD HERE,
        'username': config.name, // FILL USERNAME HERE
        'register': true,
        'contact_uri': 'sip:'+config.uri+transport
    };


    var remoteAudio = new window.Audio();
    remoteAudio.autoplay = true;
    remoteAudio.crossOrigin = "anonymous";

    if (configuration.uri && configuration.password) {
        JsSIP.debug.enable('JsSIP:*'); // more detailed debug output
        phone = new JsSIP.UA(configuration);
        phone.on('registrationFailed', function (ev) {
            alert('Registering on SIP server failed with error: ' + ev.cause);
            configuration.uri = null;
            configuration.password = null;
            updateUI();
        });
        phone.on('newRTCSession', function (ev) {
            console.log('phone newRTCSession',ev);
            var newSession = ev.session;
            if (session) { // hangup any existing call
                notif("Rechazada: "+newSession.remote_identity.toString()) ;
                newSession.terminate();
                return;
            }
            session = newSession;
            var completeSession = function () {
                session = null;
                updateUI();
            };
            session.on('ended', completeSession);
            session.on('failed', completeSession);
            session.on('accepted', updateUI);
            session.on('confirmed', function () {  
                console.log('session confirmed');
                if (session.direction === 'incoming') {
                    incomingCallAudio.pause();
                }
                var dtmfSender = session.connection.getSenders()[0].dtmf;
                session.sendDTMF = tone => {
                    dtmfSender.insertDTMF(tone);
                  };
                updateUI();
            });

            if(session.connection) {
                session.connection.ontrack = function (e) {
                    console.log('ontrack', e);
                    remoteAudio.srcObject = e.streams[0];
                };
            } else {
                session.on('peerconnection', (e) => {
                    console.log('session peerconnection', e);
                    const peerconnection = e.peerconnection;
                    peerconnection.ontrack = function (e) {
                        console.log('ontrack', e);
                        remoteAudio.srcObject = e.streams[0];
                   };
                });
            }

            if (session.direction === 'incoming') {
                incomingCallAudio.play();
                notif("Llamada entrante: "+session.remote_identity.toString()) ;
            }


            updateUI();
        });
        phone.start();
    }


}


function grantNotification() {
    if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
           console.log(permission);
        });
     }
}

function notif(str) {
    const notification = new Notification("Msg", {
        body: str,
        icon: logoAnura
     })

     notification.onclick = function () {
        window.focus();
      };
}